.poweredByStripe img {
  max-width: 500px;
}


@media screen and (max-width: 800px) {
  .poweredByStripe img {
    max-width: 100%;
  }
}
